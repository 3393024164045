var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"home"},[_c('van-swipe',{staticClass:"my-swipe",attrs:{"autoplay":3000,"indicator-color":"white"}},[_c('van-swipe-item',[_c('img',{attrs:{"src":require("../../assets/dashboard/diannao2.png"),"alt":"","width":"100%"}})])],1),(_vm.patientDict && _vm.patientDict.xm)?_c('div',{staticClass:"patient_card",on:{"click":function($event){return _vm.routeTo('/patientList',1,true)}}},[_c('div',{staticClass:"card_name"},[_vm._v(" "+_vm._s(_vm.patientDict.xm)+" "),_c('a',{staticClass:"name_sex"},[_vm._v(_vm._s(_vm.patientDict.xbmc)+" "+_vm._s(_vm.patientDict.age)+"岁 ")])]),_c('van-popover',{attrs:{"theme":"light","trigger":"click","actions":_vm.changeCardActions},on:{"select":_vm.changeCardAction},scopedSlots:_vm._u([{key:"reference",fn:function(){return [(
              _vm.patientDict.patientCardList &&
                _vm.patientDict.patientCardList.length > 0
            )?_c('div',{staticClass:"card_number",on:{"click":function($event){$event.stopPropagation();return _vm.changeCardShowAction.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.patientDict.patientCardList[0].jzklxmc))]),_c('span',{staticStyle:{"margin-left":"5px","margin-top":"2px"}},[_vm._v(_vm._s(_vm.patientDict.patientCardList[0].jzkh))]),(_vm.patientDict.patientCardList.length > 1)?_c('van-icon',{staticClass:"number",attrs:{"name":"arrow-down","color":"#4f4f4f","size":"20"}}):_vm._e()],1):_vm._e()]},proxy:true}],null,false,4017665180),model:{value:(_vm.changeCardshowPopover),callback:function ($$v) {_vm.changeCardshowPopover=$$v},expression:"changeCardshowPopover"}}),_vm._m(0)],1):_c('div',{staticClass:"add-button",on:{"click":function($event){return _vm.routeTo('/patientList',1,true)}}},[_c('van-icon',{staticClass:"icon-add",attrs:{"name":"add-o"}}),_vm._v(" 添加就诊人 "),(
        _vm.patientDict.patientCardList &&
          _vm.patientDict.patientCardList.length > 0
      )?_c('img',{attrs:{"src":require("../../assets/dashboard/xiala-@2x.png"),"alt":""}}):_vm._e()],1),_vm._m(1),_c('div',{staticClass:"home-in"},[_c('div',{staticClass:"entry-list"},[_c('div',{staticClass:"entry-item",on:{"click":function($event){return _vm.routeTo('/appoint')}}},[_c('img',{staticClass:"img",attrs:{"src":require('../../assets/onlineDashboard/yygh.png')}}),_c('div',[_vm._v("预约挂号")])]),_c('div',{staticClass:"entry-item",on:{"click":function($event){return _vm.routeTo('/expenselist')}}},[_c('img',{staticClass:"img",attrs:{"src":require('../../assets/onlineDashboard/zxwz.png')}}),_c('div',[_vm._v("在线缴费")])]),_c('div',{staticClass:"entry-item",on:{"click":function($event){return _vm.routeTo('/consultation')}}},[_c('img',{staticClass:"img",attrs:{"src":require('../../assets/onlineDashboard/qdhz.png')}}),_c('div',[_vm._v("签到/候诊")])]),_c('div',{staticClass:"entry-item",on:{"click":function($event){return _vm.routeTo('/patient')}}},[_c('img',{staticClass:"img",attrs:{"src":require('../../assets/onlineDashboard/jzrgl.png')}}),_c('div',[_vm._v("就诊人管理")])])]),_c('div',{staticClass:"card-box"},[_c('div',{staticClass:"card-title"},[_vm._v(" 住院服务 ")]),_c('div',{staticClass:"card-name"},_vm._l((_vm.allServe),function(item){return _c('div',{key:item.name,staticClass:"item",on:{"click":function($event){return _vm.routeTo(item.url)}}},[_c('img',{staticClass:"img",attrs:{"src":item.imgUrl}}),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0)])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_change"},[_c('img',{staticStyle:{"right":"105%","top":"12%","width":"20px","height":"18px"},attrs:{"src":require("../../assets/dashboard/qiehuan@2x.png"),"alt":""}}),_c('div',[_vm._v("切换就诊人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment-tips"},[_c('span',[_vm._v("当前平台仅支持自费就诊，医保暂未开通，无法支付。")])])
}]

export { render, staticRenderFns }