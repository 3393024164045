<template>
  <div class="body">
    <div class="home">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="../../assets/dashboard/diannao2.png" alt="" width="100%">
        </van-swipe-item>
      </van-swipe>
      <div
        v-if="patientDict && patientDict.xm"
        class="patient_card"
        @click="routeTo('/patientList',1,true)"
      >
        <div class="card_name">
          {{ patientDict.xm }}
          <a class="name_sex">{{ patientDict.xbmc }} {{ patientDict.age }}岁
          </a>
        </div>
        <van-popover
          v-model="changeCardshowPopover"
          theme="light"
          trigger="click"
          :actions="changeCardActions"
          @select="changeCardAction"
        >
          <template #reference>
            <div
              v-if="
                patientDict.patientCardList &&
                  patientDict.patientCardList.length > 0
              "
              class="card_number"
              @click.stop="changeCardShowAction"
            >
              <span>{{ patientDict.patientCardList[0].jzklxmc }}</span> 
              <span style="margin-left: 5px;margin-top: 2px;">{{ patientDict.patientCardList[0].jzkh }}</span> 
              <van-icon
                v-if="patientDict.patientCardList.length > 1"
                class="number"
                name="arrow-down"
                color="#4f4f4f"
                size="20"
              />
            </div>
          </template>
        </van-popover>
        <div class="card_change">
          <img src="../../assets/dashboard/qiehuan@2x.png" alt="" style="right: 105%;
    top: 12%;
    width: 20px;
    height: 18px;"
          >
          <div>切换就诊人</div> 
        </div>
      </div>
      <div v-else class="add-button" @click="routeTo('/patientList',1,true)">
        <van-icon name="add-o" class="icon-add" />
        添加就诊人
        <img v-if="
          patientDict.patientCardList &&
            patientDict.patientCardList.length > 0
        " src="../../assets/dashboard/xiala-@2x.png" alt=""
        >
      </div>
      <div class="payment-tips">
        <span>当前平台仅支持自费就诊，医保暂未开通，无法支付。</span>
      </div>
      <div class="home-in">
        <div class="entry-list">
          <div class="entry-item" @click="routeTo('/appoint')">
            <img class="img" :src="require('../../assets/onlineDashboard/yygh.png')">
            <div>预约挂号</div>
          </div>
          <div class="entry-item" @click="routeTo('/expenselist')">
            <img class="img" :src="require('../../assets/onlineDashboard/zxwz.png')">
            <div>在线缴费</div>
          </div>
          <div class="entry-item" @click="routeTo('/consultation')">
            <img class="img" :src="require('../../assets/onlineDashboard/qdhz.png')">
            <div>签到/候诊</div>
          </div>
          <div class="entry-item" @click="routeTo('/patient')">
            <img class="img" :src="require('../../assets/onlineDashboard/jzrgl.png')">
            <div>就诊人管理</div>
          </div>
        </div>
        <div class="card-box">
          <div class="card-title">
            住院服务
          </div>
          <div class="card-name">
            <div v-for="item in allServe" :key="item.name" class="item" @click="routeTo(item.url)">
              <img class="img" :src="item.imgUrl">
              <div class="txt">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gjcModel } from "@/api/gjcApi"
export default {
  name:"Home",
  data(){
    return{
      changeCardshowPopover:false, //切换就诊卡弹出层
      patientDict: {}, //就诊人信息:
      allServe: [
        {
          name: '我的预约',
          url: '/myAppointment',
          imgUrl: require('../../assets/onlineDashboard/wdyy.png')
        },
        {
          name: '我的挂号',
          url: '/myRegister',
          imgUrl: require('../../assets/onlineDashboard/wdgh.png')
        },
        {
          name: '我的问诊',
          url: '/consultation',
          imgUrl: require('../../assets/onlineDashboard/wdwz.png')
        },
        {
          name: '我的处方',
          url: '/prescription',
          imgUrl: require('../../assets/onlineDashboard/dzcf.png')
        },
        {
          name: '我的订单',
          url: '/orderList',
          imgUrl: require('../../assets/onlineDashboard/wddd.png')
        },
        // {
        //   name:'检验报告',
        //   url:'/checkout',
        //   imgUrl:require('../../assets/img/jiany.png')
        // },
        // {
        //   name:'检查报告',
        //   url:'/checkup',
        //   imgUrl:require('../../assets/img/jianc.png')
        // },
        // {
        //   name:'影像报告',
        //   url:'/imageReport',
        //   imgUrl:require('../../assets/img/serv0010.png')
        // },
        {
          name: '我的地址',
          url: '/address',
          imgUrl: require('../../assets/onlineDashboard/wddz.png')
        }
        
        // {
        //   name: '价格公示',
        //   url: '/pricePublicity',
        //   imgUrl: require('../../assets/img/serv007.png')
        // }
      ]
    }
  },
  activated(){
    if (this.$store.getters.token) { 
      this.getHomePatientListData()
    }else{
      this.patientDict = {}
      this.changeCardActions = []
      this.$store.commit("user/setPatient", '')
    }
  },
  methods:{
  //跳转页面
  rejectMth(){
      let token = localStorage.getItem("token")
      if (token == "null" ) {
        this.$router.push({
          path:'/login'
        })
        return true
      }
    },
    routeTo(url, type, require) {
      const data = {
        yydm: "",
        page: 1,
        pageSize: "10"
      }
     
      if(require){
        if(this.rejectMth()){
        return
      }
        gjcModel.getPatientListData(data).then((res) => {
        console.log("获取就诊人列表", res)
        this.patientList = res.data
        if (require && this.patientList.length == 0) {
        this.$toast({
          message: "请先添加就诊人"
        })
        this.$router.push("/patientList")
        return
      }
      })
      }
      
      if (type) {
        this.$router.push({
          path: url,
          query: {
            type: type
          }
        })
      } else {
        this.$router.push(url)
      }
    },
    //首页就诊人列表
    getHomePatientListData() {
      const params = {
        yydm: "",
        page: "",
        pageSize: ""
      }
      gjcModel.getHomePatientListData(params).then((res) => {
        console.log("首页就诊人列表", res.data)
        this.patientDict = res.data
        this.changeCardActions = []
        const cardList = res.data.patientCardList || []
        cardList.forEach((item) => {
          let cartDic = {}
          cartDic.text = item.jzklxmc + item.jzkh
          cartDic.id = item.patientCardId
          cartDic.patientId = res.data.patientId
          this.changeCardActions.push(cartDic)
        })
        this.$store.commit("user/setPatient", JSON.stringify(res.data))
      })
    },
    changeCardShowAction() {
      if (this.patientDict.patientCardList.length > 1) {
        this.changeCardshowPopover = true
      }
    },
    //切换就诊卡
    changeCardAction(action, index) {
      this.changeCardshowPopover = false
      console.log("切换就诊卡", action, index)
      gjcModel
        .getSetDefaultPatientCardData(action.patientId, action.id)
        .then((res) => {
          console.log("切换就诊卡", res)
          this.getHomePatientListData()
        })
    },
    // routeTo(url){
    //   this.$router.push(url)
    // },
    kefu(){
      this.$dialog.alert({
        message: "联系电话：<a href='tel:0755-6111-9999'>0755-6111-9999</a>",
        closeOnClickOverlay:true
      })
    }
  }

}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
view {
  box-sizing: border-box;
}
.body{
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 999;
  overflow: auto;
  background: #F5F8FA;
}

.payment-tips{
  font-size: 26px;
  text-align: center;
  width: 686px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 12px auto 0;
  border-radius: 12px;
  color: #f56c6c;
  background: #fef0f0;
  line-height: 50px;
  height: 50px;
  border: 1px solid #ffe6e6;
}
.patient_card {
  position: relative;
  width: 686px;
  height: 150px;
  margin: 24px auto 0;
  margin-bottom: 10px;
  padding: 10px 0;
  background: linear-gradient(180deg, #69bbf1 0%, #3a8ae5 100%);
  border-radius: 12px;
  color: #4f4f4f;
  background: #FFFFFF;
    border-radius: 24px;
    opacity: 0.88;
    margin-top: -85px;
   
  .card_name {
    margin-left: 24px;
    padding-top: 20px;
    font-size: 32px;
    font-weight: bold;
    line-height: 60px;

    .name_sex {
      font-size: 28px;
    }
  }

  .card_number {
    display: flex;
    margin-left: 24px;
    height: 30px;
    margin-top: 0px;
    font-size: 28px;

    .number {
      margin-left: 10px;
    }
  }

  .card_change {
    position: absolute;
    top: 46px;
    right: 32px;
    width: 147px;
    height: 47px;
    font-size: 26px;
    color: #1e8dff;
    line-height: 47px;
    background-color: white;
    border-radius: 5px;
    text-align: center;
  }

  img {
    position: absolute;
    top: 21px;
    right: 32px;
    width: 97px;
    height: 97px;
  }
}
.add-button {
  position: relative;
  width: 686px;
  height: 140px;
  text-align: center;
  line-height: 140px;
  margin: 24px auto 0;
  color: #ffffff;
  font-size: 32px;
  background: linear-gradient(180deg, #69bbf1 0%, #3a8ae5 100%);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    top: 21px;
    right: 32px;
    width: 97px;
    height: 97px;
  }

  .icon-add {
    font-size: 42px;
    position: relative;
    // top: 10px;
    right: 20px;
  }
}

  .home-in {
    position: relative;
    z-index: 10;
    padding: 12px 32px 24px;
  }


  .entry-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 40px 16px;
    font-size: @font-size-26;
    color: #3d3d3d;
    text-align: center;
    background: #fff;
    border-radius: 12px;

    .entry-item {
      width: 160px;

      .img {
        display: block;
        margin: 0 auto;
        width: 116px;
        height: 116px;
      }
    }
  }
  .card-box {
  display: flex;
  flex-flow: wrap;
  // justify-content: space-between;
  margin-bottom: 24px;
  // padding: 40px 16px 0;
  background: #fff;
  border-radius: 12px;
  z-index: 2;
  flex-direction: column;
.card-name{
  display: flex;
  flex-wrap: wrap;
}
  .item {
    //  width: 120px;
    width: 25%;
    text-align: center;
    margin-bottom: 15px;

    img {
      width: 48px;
      height: 48px;
      display: block;
      margin: 0 auto;
    }
  }
}

.card-title {
  font-size: 32px;
  color: #333333;
  padding: 24px;
}
 .txt{
    text-align: center;
    color: #333333;
    font-size: 24px;
    margin-top: 18px;
  }


  .row-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .item{
      width: 213px;
      padding:20px;
      text-align: center;
      background: #FFFFFF;
      border-radius: 12px;
      font-size: 24px;
      box-sizing: border-box;
      img{
        width: 56px;
        height: 56px;
        display: block;
        margin: 0 auto;
      }
    }
  }


</style>
